import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestComponentComponent } from './test-component/test-component.component';
import { AuthGuard } from 'portal';

const views = () => import('portal').then(x => x.ViewsModule);

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('portal').then(x => { return x.ViewsModule })
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
