import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService, StoreService } from 'portal';

@Component({
  selector: 'app-test-component',
  templateUrl: './test-component.component.html',
  styleUrls: ['./test-component.component.scss']
})
export class TestComponentComponent {

  config: any;
  page: any;
  aboutBadgeText: string = '';

  constructor(private env: EnvironmentService, private storeService: StoreService) { }

  ngOnInit(): void {
    this.env.config.subscribe(x => {
      this.config = x;
      this.page = x.aboutPage;
      this.aboutBadgeText = this.page.aboutBadgeText;

      this.storeService.setTitle(this.page.title);
    });
  }
}