<div class="padd-top">
    <div class="hero-wrapper">
        <sketch-statichero [title]="page?.title" theStyle="darkergrey" [showArrow]="true" [primaryArrow]="true"
            [summary]="page?.summary" [image]="page?.image"></sketch-statichero>
    </div>
    <div class="py-5 about">
        <div class="container">

            <div class="row">
                <div class="col-md-6 first-col">
                    <span class="small-title bold uppercase font-gothic colour-primary mb-2">
                        About Us
                    </span>
                    <h2 class="colour-white" [innerHTML]="page.subTitle">
                    </h2>
                    <div [innerHtml]="page.content" class="font-gothic abouthome--text colour-white">
                    </div>

                    <div [innerHtml]="page.contentTwo"
                        class="font-gothic abouthome--text colour-white hide-desktop">

                    </div>

                    <div class="staggered-image">
                        <span class="image-badge bg-midgrey colour-white" *ngIf="aboutBadgeText != ''">
                            <span [innerHTML]="aboutBadgeText">
                            </span>
                        </span>
                        <img [src]="page.imageOne" alt="" />
                    </div>


                </div>
                <div class="col-md-6 hide-mobile">
                    <div class="staggered-image staggered-2">
                        <img [src]="page.imageTwo" alt="" />
                    </div>
                    <div [innerHtml]="page.contentTwo" class="font-gothic abouthome--text colour-white">

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="what-we-do">
        <sketch-stats></sketch-stats>
    </div>

    <div class="dashboard py-5 bg-white">
        <div class="dashboard-banner">
            <sketch-slick-trending></sketch-slick-trending>
        </div>
    </div>

</div>
<sketch-accountblocks></sketch-accountblocks>