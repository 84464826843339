<header class="loggedin--header" id="loggedin--header-id" *ngIf="show"
    [ngClass]="hasBg || isScrolled || loggedIn ? 'has-bg' : ''">

    <sketch-preheader></sketch-preheader>

    <div class="wide-container">

        <div class="overlay" [ngClass]="navOn ? 'active' : ''" (click)="hideNav()"></div>
        <div>

            <div class="d-flex justify-content-between align-items-center nav-outer">

                <a (click)="doAction( $event, '/')" class="logo-outer" *ngIf="doLogoOverride == false || overrideLogo == false">
                    <img src="{{config.logo}}" alt="{{config.companyName}}" class="logo">
                </a>

                <a (click)="doAction( $event, '/')" class="logo-outer override-logo" *ngIf="doLogoOverride == true && overrideLogo == true">
                    <img src="/images/{{ customLogo }}" alt="{{config.companyName}}" class="logo">
                </a>
                <div class="header-left">
                    <div class="header--search">
                        <div class="d-flex justify-content-between">
                            <div class="searchbar__container">
                                <input type="text" name="search" [(ngModel)]="searchText" class="form-control"
                                    (ngModelChange)="doTextChange($event)" placeholder="Search Products" />
                                <button class="btn btn-primary" (click)="doSearch($event)">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>



                <a (click)="exposeNavigation( $event )" class="mobile-nav">
                    <img [src]="assets?.mobileTrigger" alt="Trigger Mobile">
                </a>



                <div class="right-hand-nav">

                    <div class="d-flex align-items-center">
                        <div>
                            <ul class="d-flex main-navigation align-items-center w-100" *ngIf="!loggedIn">
                                <ng-container *ngFor="let o of config.nav">
                                    <li *ngIf="o.showDesktop">
                                        <a (click)="doAction( $event, o.link)"
                                            [ngClass]="isActive(o.link)">{{o.text}}</a>
                                    </li>
                                </ng-container>
                            </ul>

                            <ul class="d-flex  main-navigation align-items-center w-100" *ngIf="loggedIn">
                                <ng-container *ngFor="let o of config.nav">
                                    <li *ngIf="o.showDesktop">
                                        <a (click)="doAction( $event, o.link )"
                                            [ngClass]="isActive(o.link)">{{o.text}}</a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                        <div>

                            <nav>

                                <ul>

                                    <li class="icon-link" [ngClass]="isActive('/account')">
                                        <a (click)="triggerAccount( $event )">
                                            <img [src]="assets?.userIcon" /><span>Account</span>
                                        </a>
                                    </li>
                                    <li class="icon-link last-icon-link">
                                        <a (click)="triggerBasket( $event )" class="cart-icon" id="cart">
                                            <img [src]="assets?.cartIcon" />
                                            <span class="cart-count bg-primary"
                                                *ngIf="cartTotal > 0">{{cartTotal}}</span>
                                            <span>Basket</span>
                                        </a>
                                    </li>


                                    <li class="account-dropdown" *ngIf="showAccountDropdown && loggedIn">
                                        <span class="account-no">Account No: <span>{{customerId}}</span><span
                                                class="customer-name">{{customerName}}</span><span
                                                class="down-chevron"><img [src]="downArrow" /></span></span>
                                        <ul>
                                            <ng-container *ngFor="let a of accountLinks; ">
                                                <li *ngIf="!suppressed(a.link)">
                                                    <a [routerLink]="a.link">{{a.text}}</a>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </li>
                                    <li *ngIf="config.featuredLink && !loggedIn" class="button-link">
                                        <a class="btn btn-primary"
                                            (click)="doAction( $event, config.featuredLink.link)">{{config.featuredLink.text}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>




            <div [ngClass]="navOn ? 'navigation-active mob-nav' : 'navigation-inactive mob-nav'">

                <a (click)="doAction( $event, '/')" class="mobile-logo">
                    <img src="{{config.logo}}" alt="{{config.companyName}}" class="logo">
                </a>

                <a (click)="exposeNavigation( $event )" class="mobile-nav">
                    <img [src]="assets?.mobileTrigger" alt="Trigger Mobile">
                </a>

                <nav *ngIf="loggedIn">
                    <ul>
                        <li *ngFor="let o of config.loggedInNav">
                            <a (click)="doAction( $event, o.link )" [ngClass]="isActive(o.link)">{{o.text}}</a>
                        </li>

                        <li class="icon-link" [ngClass]="isActive('/account')">
                            <a (click)="triggerAccount( $event )">
                                <img [src]="assets?.userWhite" /><span class="hide-desktop">My Account</span>
                            </a>
                        </li>
                        <li class="icon-link">
                            <a (click)="doSearch( $event )" [ngClass]="isActive('/search')">
                                <img [src]="assets?.searchIcon" /><span class="hide-desktop">Search</span>
                            </a>
                        </li>
                    </ul>

                </nav>


                <div class="mob-nav-footer">

                    <div class="footer-dropdown" *ngIf="showAccountDropdown && loggedIn">
                        <span class="account-no">Account No: <span>{{customerId}}</span><span
                                class="customer-name">{{customerName}}</span></span>
                    </div>
                    <a (click)="triggerLogout( $event )" class="logout btn btn-primary" id="logout">
                        Logout
                    </a>

                </div>



            </div>
        </div>

    </div>
</header>