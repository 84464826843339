// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { cookiesText } from "./legal/cookies";
import { privacyText } from "./legal/privacy";
import { termsText } from "./legal/terms";
import { priceListConfig } from "./pricelist/config";

export const environment = {
  production: false,
  webUrl: 'localhost',
  storagePrefix: 'AA_',
  storageHash: 'AAQDDA',
  apiUrl: 'https://api.aandacarpets.co.uk/api/v1/mayne',
  emailUrl: 'https://api.aandacarpets.co.uk/api/v1/email',
  imageUrl: 'https://uploads.likewisefloors.co.uk/uploads/',
  logo: 'assets/images/logos/A&A_Logo.svg',
  footerLogo: 'assets/images/logos/A&A_Logo.svg',
  altLogo: 'assets/images/logos/paysmart.png',
  referrer: 'aandacarpets.co.uk',
  showStatements: true,
  enableCollection: true,
  enableClearance: true,
  stockBehaviour: {
    enablePresold: true,
    enableCutOffClosedRoll: false,
  },
  siteUnits: {
    enabledUnits: ['metric'],
    useDropdown: false,
  },
  enableQtyBreaks: true,
  defaultCheckoutDate: true,
  enableHomePage: false,
  enableSpecials: true,
  showEstimatedDelivery: false,
  enablePriceSuppression: true,
  enableCartSuppression: false,
  forceNextWorkingDay: true,
  source: 'AAWeb',
  enableCustomerRef: true,
  googleTagManager: '',
  enableOverridelogo: true,
  collectionMessage: 'Please select your preferred collection date below. To ensure your order is ready for collection, please call your local branch to let us know you are on your way.',
  paymentContact: 'If you need to change or update your billing details, please contact us 01924 460638',
  fullDeliveryText: 'If you\'d prefer to have your full order delivered on the same day, please select one date below.',
  postDeliveryText: 'In an effort to minimise our carbon footprint, we are encouraging all customers to align delivery dates of multiple orders.',
  cartSuccess: 'You have successfully added to the cart',
  customerServiceNumber: '0161 714 4111',
  cta: {
    title: 'Can`t find what you`re looking for?',
    content: 'For more information or assistance, speak to our team',
    button: 'Contact Us',
    link: 'contact',
  },
  tooltips: {
    enableTooltips: true,
    options: {
      presold: {
        title: 'Pre-Order',
        text: 'We are currently awaiting stock to arrive from the manufacturers. Your order will be despatched shortly after the date displayed',
      },
      outOfStock: {
        title: 'Out of Stock',
        text: 'Please contact our Customer Service Team for more information on expected arrival of new stock',
      },
      inStock: null
    }
  },
  icons: {
    accountIcon: 'assets/images/icons/layout.svg',
    ordersIcon: 'assets/images/icons/tag.svg',
    infoIcon: 'assets/images/icons/setting.svg',
    passwordIcon: 'assets/images/icons/lock.svg',
    logoutIcon: 'assets/images/icons/log-out.svg',
    myAccountInfoIcon: 'assets/images/icons/log-out.svg',
    accountSummaryIcon: 'assets/images/icons/log-out.svg',
    chevronRightIcon: 'assets/images/icons/chevron_right.svg',
    chevronLeftIcon: 'assets/images/icons/chevron_left.svg',
    searchIcon: 'assets/images/icons/search_white.svg',
    closeIcon: 'assets/images/icons/close.svg',
    cartIcon: 'assets/images/icons/cart.svg',
    downArrow: 'assets/images/icons/down-chevron.svg',
    downArrowPrimary: 'assets/images/icons/down-arrow-primary-colour.svg',
    searchIconDark: 'assets/images/icons/search.svg',
    userWhite: 'assets/images/icons/user_white.svg',
    userIcon: 'assets/images/icons/user.svg',
    cartIconWhite: 'assets/images/icons/cart_white.svg',
    circleEmpty: 'assets/images/icons/circle-empty.svg',
    circleSelected: 'assets/images/icons/circle-selected.svg',
    checkIcon: 'assets/images/icons/check-icon.svg',
    backIcon: 'assets/images/icons/back-icon.svg',
    cancelIcon: 'assets/images/icons/cancel.svg',
    dangerIcon: 'assets/images/icons/danger.svg',
    mobileTrigger: 'assets/images/icons/mobile.svg',
    missingIcon: 'assets/images/svgs/missing.svg',
    mobileHome: 'assets/images/icons/footernav/home.svg',
    mobileList: 'assets/images/icons/footernav/list.svg',
    mobileSearch: 'assets/images/icons/footernav/search.svg',
    mobileCart: 'assets/images/icons/footernav/user.svg',
    confirmationCheck: 'assets/images/icons/confirmationcheck.svg',
    navigationChevron: 'assets/images/icons/navigation-chevron.svg',
    pricelistIcon: 'assets/images/icons/user_white.svg',
  },
  companyName: 'A&A Carpets',
  companyDetails: {
    addressLineOne: 'Oakhill Trading Estate',
    addressLineTwo: 'Devonshire Road',
    town: 'Worsley',
    postcode: 'M28 3PT'
  },
  aboutPage: {
    name: "About Us",
    title: "About Us",
    subTitle: "A&A Carpets<br/>Carpets & flooring",
    content: "We hold stocks of a huge range of flooring products sourced globally from world class manufacturers covering both residential and commercial sectors. Our extensive product range includes carpet, vinyl, laminate, LVT, artificial grass and more.",
    contentTwo: "We have an expanding distribution network covering most of the UK offering a next day delivery service. Our team of experts and customer care team are available to help with any enquiries you may have. Please give us a call or drop us an email.",
    imageOne: 'assets/images/about/rolls.png',
    imageTwo: 'assets/images/about/worker.png',
    aboutBadgeText: '',
    image: 'assets/images/categories/C_Carpet.jpg',
    heroStyle: 'full'
  },

  nav: [
    {
      link: '/categories',
      text: 'Categories',
      showDesktop: true,
    },
    {
      link: '/about', text: 'About Us',
      showDesktop: true,
    },
    {
      link: '/getintouch', text: 'Get in Touch',
      showDesktop: true,
    }
  ],
  loggedInNav: [
    {
      link: '/dashboard',
      text: 'Dashboard',
      showDesktop: true,
    },
    {
      link: '/category/C', text: 'Carpets',
      showDesktop: false,
    },
    {
      link: '/category/V', text: 'Luxury Vinyl',
      showDesktop: false,
    },
    {
      link: '/category/N', text: 'Laminate',
      showDesktop: false,
    },
    {
      text: 'Luxury Vinyl Tile', link: '/category/L',
      showOnDesktop: false
    },
    {
      link: '/category/G', text: 'Artificial Grass',
      showDesktop: false,
    },
    {
      link: '/category/A', text: 'Accessories',
      showDesktop: false,
    },
    {
      link: '/category/T', text: 'Carpet Tiles & Matting',
      showDesktop: false,
    },
    {
      link: '/category/H', text: 'Underlay',
      showDesktop: false,
    },
    {
      link: '/category/E', text: 'Contract',
      showDesktop: false,
    },
    {
      link: '/category/B', text: 'Adhesives & Screeds',
      showDesktop: false,
    },
    {
      link: '/category/Q', text: 'Matting',
      showDesktop: false,
    },
    {
      link: '/categories', text: 'View All',
      showDesktop: false,
    },
    {
      link: '/account/orders', text: 'My Orders',
      showDesktop: true,
    },
    {
      link: '/account/pricelists',
      text: 'My Pricelists',
      showDesktop: false,
    },
    {
      link: '/about', text: 'About',
      showDesktop: true,
    },
  ],
  featuredLink: {
    link: '/login', text: 'Login'
  },

  home: {
    title: "",
    text: "",
    buttons: [
      {
        link: '/login', text: 'Login',
      },
      {
        link: '/getintouch', text: 'Get in Touch', type: 'modal'
      }
    ],
    slides: [
      'assets/images/home/bed-g4f388bb60_1280.jpg',
      'assets/images/home/cadenza-min.jpg'
    ],
    content: {
      title: "ABOUT US",
      subTitle: "",
      text: "",
      features: [
        'assets/images/home/HomeFeature.jpg',
        'assets/images/home/HomeFeature2.png'
      ],
      buttons: [
        {
          link: '/login', text: 'Login',
        },
        {
          link: '/getintouch', text: 'Get in Touch', type: 'modal'
        }
      ],
    }
  },
  accountBlocks: [
    {
      title: 'Browse Catalogue',
      text: '',
      link: 'categories',
      image: 'assets/images/home/GetInTouch.jpg'
    },
    {
      title: 'My Orders',
      text: '',
      link: 'orders',
      image: 'assets/images/home/MyAccount.jpg'
    },
    {
      title: 'My Account',
      text: '',
      link: 'account',
      image: 'assets/images/Exquisite Mirror Mirror-min.jpg'
    }
  ],
  homeCards: [
    {
      title: 'My Account',
      text: 'Place and manage orders, update billing details through your account.',
      link: 'account',
      image: 'assets/images/home/MyAccount.jpg'
    },
    {
      title: 'Get in Touch',
      text: 'Got a query? Contact our friendly team by email or phone. ',
      link: 'getintouch',
      image: 'assets/images/home/GetInTouch.jpg'
    },
    {
      title: 'Create Account',
      text: 'Set up an account to view and manage your orders.',
      link: 'createaccount',
      image: 'assets/images/home/HomeFeature.jpg'
    }
  ],
  footerContact: {
    email: 'sales@aandacarpets.co.uk',
    phone: '01204 701188',
    support: '01204 701188',
    sales: '01204 703250 '

  },
  footerNav: [
    {
      link: '/dashboard', text: 'Dashboard',
    },
    {
      link: '/categories', text: 'Categories'
    },
    {
      link: '/account/orders', text: 'My Orders'
    },
    {
      link: '/account/pricelists',
      text: 'My Pricelists',
    },
    {
      link: '/about', text: 'About'
    },
    {
      link: '/contact', text: 'Contact'
    },
    {
      link: '/account', text: 'My Account'
    }

  ],
  footerAdditional: [
    {
      link: '/privacy', text: 'Privacy Policy',
    },
    {
      link: '/terms', text: 'Terms and Conditions'
    },
    {
      link: '/cookies', text: 'Cookies'
    },
  ],
  additionalPages: [
    {
      url: 'about',
      title: "About",
      summary: "We hold stocks of a huge range of flooring products sourced globally from world class manufacturers covering both residential and commercial sectors. Our extensive product range includes carpet, vinyl, laminate, LVT, artificial grass and more.",
      text: "We have an expanding distribution network covering most of the UK offering a next day delivery service. Our team of experts and customer care team are available to help with any enquiries you may have. Please give us a call or drop us an email. ",
      image: "assets/images/home/bed-g4f388bb60_1280.jpg"
    },
    {
      url: 'hello',
      title: "About",
      summary: "We hold stocks of a huge range of flooring products sourced globally from world class manufacturers covering both residential and commercial sectors. Our extensive product range includes carpet, vinyl, laminate, LVT, artificial grass and more.",
      text: "We have an expanding distribution network covering most of the UK offering a next day delivery service. Our team of experts and customer care team are available to help with any enquiries you may have. Please give us a call or drop us an email. ",
      image: "assets/images/home/bed-g4f388bb60_1280.jpg"
    }
  ],
  categories: [
    { name: 'Carpet', image: 'assets/images/categories/C_Carpet.jpg', summary: '', fullSummary: '', bannerImage: 'assets/images/categories/C_Carpet.jpg', code: 'C' },
    { name: 'Vinyl', image: 'assets/images/categories/V_Vinyl.jpg', summary: '', fullSummary: '', bannerImage: 'assets/images/banners/V_Banner_Vinyl.jpg', code: 'V' },
    { name: 'Laminate', image: 'assets/images/categories/N_Laminate.jpg', summary: '', fullSummary: '', bannerImage: 'assets/images/banners/N_Laminate.jpg', code: 'N' },
    { name: 'Luxury Vinyl Tile', image: 'assets/images/categories/L_LVT.jpg', summary: '', fullSummary: '', bannerImage: 'assets/images/banners/L_LVT.jpg', code: 'L' },
    { name: 'Underlay', image: 'assets/images/categories/H_Underlay.jpg', summary: '', fullSummary: '', bannerImage: 'assets/images/banners/H_Underlay.jpg', code: 'H' },
    { name: 'Artificial Grass', image: 'assets/images/categories/G_Grass.jpeg', summary: '', fullSummary: '', bannerImage: 'assets/images/banners/G_Grass.jpg', code: 'G' },
    { name: 'Carpet Tiles', image: 'assets/images/categories/T_Carpet_Tiles.jpg', summary: '', fullSummary: '', bannerImage: 'assets/images/banners/T_CarpetTile.jpg', code: 'T' },
    { name: 'Accessories', image: 'assets/images/categories/Cat_A_Accessories_V2.jpeg', summary: '', fullSummary: '', bannerImage: 'assets/images/banners/accessories.jpg', code: 'A' },
    { name: 'Contract', image: 'assets/images/categories/E_Contract.jpg', summary: '', fullSummary: '', bannerImage: 'assets/images/banners/E_Contract.jpg', code: 'E' },
    { name: 'Adhesives & Screeds', image: 'assets/images/categories/B_Adhesive_Screeds.jpg', summary: '', fullSummary: '', bannerImage: 'assets/images/banners/B_Adhesives_Screeds.jpg', code: 'B' },
    { name: 'Matting', image: 'assets/images/categories/Q_Matting.jpg', summary: '', fullSummary: '', bannerImage: 'assets/images/banners/Q_Matting.jpg', code: 'Q' },
    { name: 'Clearance', image: 'assets/images/clearance/HV_ClearanceSpecials_WebBanner_CategoryCover_V2.jpg', summary: '', fullSummary: '', bannerImage: 'assets/images/banners/Q_Matting.jpg', code: 'CS' },

  ],
  trending: null,
  categoriesPage: {
    name: 'Product Catalogue',
    summary: 'Discover our exceptional range of carpet, tiles, and flooring solutions, designed to transform any space with style and durability.',
    image: 'assets/images/categories/C_Carpet.jpg',
  },
  checkoutNotice: null,
  passwordResetMessage: 'Our support team will be in touch soon to help resolve this issue.',
  contactSuccessMessage: 'Your message has been sent!',
  headerStrings: [
    { key: 'Rolls-Cuts', value: 'Select Colour' },
    { key: 'Units', value: 'Select Style' },
    { key: 'Packs-Pallets', value: '' },
    { key: 'Units-Pallets', value: '' },
  ],
  copyRightText: 'A&A Carpets. All Rights Reserved.',
  businessUpdatedText: 'A request has been sent to update your business details in our system.',
  contactUpdatedText: 'A request has been sent to update your contact details in our system.',
  showMyOrdersNotice: true,
  login: {
    forgottenPasswordPosition: 1,
    loginImages: [
      'assets/images/login/HandV_login.jpg',
    ],
  },
  contact: {
    showSales: false,
    sales: {
      telephone: "",
      email: "",
    },
    showGeneral: false,
    general: {
      telephone: "",
      email: "",
    }
  },
  createAccount: {
    title: "Create an Account",
    subTitle: "Become an A&A Carpets Customer",
    success: "Thank you for your application, one of our team will be in touch soon."
  },
  postFooterMessage: '',
  privacyPolicy: {
    title: "Privacy Policy",
    name: "Privacy Policy",
    summary: "",
    text: privacyText,
    image: "assets/images/categories/C_Carpet.jpg",
    heroStyle: 'legal',
    showBreacrumb: true,
    showAccountLinks: true,
    showBlocks: true,
  },
  terms: {
    title: "Terms & Conditions",
    summary: "",
    text: termsText,
    image: "assets/images/categories/C_Carpet.jpg",
    heroStyle: 'legal',
    showBreacrumb: true,
    showAccountLinks: true,
    showBlocks: true,
  },
  products: {
    showBreacrumb: true,
    key: 'quality_full_name',
    showCTA: false,
    popupCart: true,
    sections: [
      {
        mobileOrder: 0,
        hideMobile: true,
        subSections: [
          { component: "BigImage", hideMobile: true }
        ]
      },
      {
        mobileOrder: 1,
        subSections: [
          { component: "ProductInfo" },
          { component: "BigImage", hideDesktop: true },
          { component: "ColorPicker", args: { hideOnSelection: false } }
        ]
      },
      {
        mobileOrder: 2,
        cols: 2,
        subSections: [
          { component: "OrderEntry", args: { showSummary: true, useTabs: false, cardStyle: 'default' } }
        ]
      },
    ]
  },
  languages: {
    findProducts: 'Find Products',
    searchCatalog: 'Search Product Catalogue',
    explore: null,
    updatePassword: 'Change Password',
    requestPassword: 'Password Reset Request',

    categoriesPagePreTitle: 'Explore Products',
    categoriesPageTitle: 'Choose from our wide range of products'
  },
  dashboard: {
    showBreadcrumb: false,
    showDashboardSlider: true,
    showDashboardSearch: true,
    showDashboardWelcome: false,
    heroStyle: "cta",
    rotationSpeed: 100,
    features: [
      {
        title: 'Kempton Twist 10 colours<br/>4&amp;5m wide<br/>Felt &amp; Action back', pretitle: 'New Range Launch February',
        'image': 'assets/images/home/Home_banner.jpg'
      },
    ],
  },
  preHeader: {
    showPreHeader: true,
    showClearance: true,
    contact: 'Sales: 01204 701188 | Contract Sales: 01204 703250',
    links: [
      {
        title: 'sales@aandacarpets.co.uk',
        link: 'mailto:sales@aandacarpets.co.uk'
      },
      {
        title: 'Contact',
        link: '/contact'
      },
    ],
    clearanceSlider: [
      {
        text: 'Next Day Delivery on Orders before 4:00pm',
        link: ''
      },
      {
        text: 'Cearance and Specials <strong>Now Available</strong>',
        link: '/clearance'
      },
    ]
  },
  clearance: {
    name: 'Clearance and Specials', 
    bannerImage: 'assets/images/trending/trending carpet-min.jpg',
    heroStyle: 'full'
  },
  accountDropdown: {
    show: true,
    links: [
      {
        link: '/account', text: 'Account'
      },
      {
        link: '/account/statement', text: 'Account Statement'
      },
      {
        link: '/account/pricelists', text: 'My Pricelists'
      },
      {
        link: '/account/orders', text: 'My Orders'
      },
      {
        link: '/account/update-account-info', text: 'Update Information'
      },
      {
        link: '/accountpassword', text: 'Change Password'
      },
      {
        link: '/logout', text: 'Log Out'
      }
    ]
  },
  showGreeting: false,
  categoryComponent: {
    showCTA: false,
    showBreadcrumb: true,
    showBottomResultCount: true,
    hero: {
      showArrow: true,
      showSummary: true,
      style: 'full'
    },
    perPage: 24
  },
  myAccount: {
    showIcons: false,
    showLogout: true,
    showDelete: true,
    showAmendmentNote: false,
    showBreadcrumb: true,
    showAccountHeader: true,
    headerImage: 'assets/images/categories/C_Carpet.jpg',
    showTotalOrders: false,
    showEditOnCard: false,
    showOrdersAmendmentNote: true,
    totalOrdersPosition: 2,
    showOrderTitle: false,
    showCTA: true,
    singlePageUpdate: true,
    showLabels: false,
    orderHistoryStyle: 2,
    showPricelist: true,
    priceList: {
      showTitle: false,
      categories: [
        'C', 'N', 'G', 'V', 'N', 'L', 'H', 'E', 'T', 'B', 'A', 'Q', 'J'
      ],
    }
  },
  cookiePolicy: {
    summary: null,
    image: 'assets/images/categories/C_Carpet.jpg',
    text: cookiesText,
    title: 'Cookie Policy',
    showBreacrumb: true,
    showAccountLinks: true,
    showBlocks: true,
    heroStyle: 'legal',
  },
  hero: {
    showBreadcrumb: true,
  },
  priceListConfig: priceListConfig
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
