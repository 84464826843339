import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { EnvironmentService, StoreService, PopupService, TemplateService, OrderService, IconSet, getCustomerID, } from 'portal';

@Component({
  selector: 'sketch-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  loggedIn: boolean = false;
  config: any;
  show: boolean = true;
  assets: IconSet | null = null;
  cartTotal: number = 0;
  navOn: boolean = false;
  hasBg: boolean = true;
  isScrolled: boolean = false;
  activePath: string = '';
  showAccountDropdown: boolean = false;
  customerId: string = '';
  accountLinks: any[] = [];
  obs: Subscription[] = [];
  downArrow: string = '';
  searchText: string = '';
  customerName: string = '';
  overrideLogo: boolean = false;
  doLogoOverride: boolean = false;
  customLogo: string = '';

  showPricelist: boolean = false;
  suppressPrices: boolean = false;
  filteredHeader: any = []; 

  constructor(private popupService: PopupService, private router: Router, private envService: EnvironmentService, private orderService: OrderService, @Inject(DOCUMENT) document: any,
    private storeService: StoreService,) { }

  ngOnInit(): void {
    this.loggedIn = this.storeService.getCustomerId() != null;

    this.obs.push(this.storeService.getSuppressPrices().subscribe(x => {
      this.suppressPrices = x;
      this.filteredHeader = this.accountLinks.filter(y => this.accountLinkIsAvailable(y.link));
    }));

    this.obs.push(this.storeService.customer.subscribe((x: any) => {

      this.doLogoOverride = false;

      if (x != null && x.hasOwnProperty('NAME') == false) {
        this.loggedIn = false;
        return;
      }

      this.customerName = x ? x?.NAME.toLowerCase() : '';


      if (x && x.hasOwnProperty('overridelogo') && x.overridelogo != '' && this.overrideLogo) {
        this.doLogoOverride = true;
        this.customLogo = x.overridelogo;
      }

      this.loggedIn = x != null && x != '';

      if (x == null) {
        //  this.storeService.refreshCustomerDetails();
        this.customLogo  = '';
        return;
      }
    }));


    this.obs.push(this.envService.config.subscribe(x => {
      this.config = x;
      this.overrideLogo = this.envService.extractSetting(x, 'enableOverridelogo', false);

      console.log( this.overrideLogo ); 

      this.showPricelist = this.envService.extractSetting(x, 'myAccount.showPricelist', true);
      this.storeService.getSuppressPrices().subscribe(x => { this.suppressPrices = x; });

      if (!x.hasOwnProperty('icons') || (x as any)['icons'] == null || (x as any)['icons'] == undefined) {
        this.assets = null;
      } else {
        this.assets = x.icons;
        this.downArrow = this.assets?.hasOwnProperty('navigationChevron') ? this.assets.navigationChevron : '';
      }

      if (x.hasOwnProperty('accountDropdown')) {
        this.showAccountDropdown = x.accountDropdown.hasOwnProperty('show') ? x.accountDropdown.show : false;
        this.accountLinks = x.accountDropdown.hasOwnProperty('links') ? x.accountDropdown.links : [];
        this.filteredHeader = this.accountLinks.filter(y => this.accountLinkIsAvailable(y.link));
      }

    }));


    this.customerId = getCustomerID();
    this.obs.push(this.storeService.toggleCustomerId.subscribe((x: any) => {
      this.customerId = x;
    }));
    //
    let o = this.orderService.cartItems?.subscribe((x: any) => {
      this.cartTotal = x != null ? x.length : 0;
    });

    if (o) { this.obs.push(o); }

    let o1 = this.popupService.toggleShowNav.subscribe((x: any) => {
      this.show = x;
    });

    if (o1) { this.obs.push(o1); }

    let o2 = this.popupService.toggleNavBg.subscribe((x: any) => {
      this.hasBg = x;
    });

    if (o2) { this.obs.push(o2); }

    this.activePath = this.router.url;
  }

  ngOnChanges() {
    this.activePath = this.router.url;
  }

  suppressed(link: string) {
    switch (link) {
      case '/account/pricelists':
        // showPricelist && !suppressPrices
        if (!this.showPricelist || this.suppressPrices) {
          return true;
        }
        break;
      case '/account/statement':
        // config.showStatements  && !suppressPrices
        if (!this.config.showStatements || this.suppressPrices) {
          return true;
        }
        break;
    }

    return false;
  }

  triggerAccount(event: any) {
    this.navOn = false;
    this.router.navigate(['/account']);
  }

  triggerBasket(event: any) {
    event.preventDefault();
    this.navOn = false;
    this.popupService.toggleCart(true);
  }

  isActive(link: string) {
    return this.activePath == link ? 'active' : '';
  }

  doAction(event: any, link: string) {
    event.preventDefault();
    this.navOn = false;

    //Redirect to the dashboard if we click home and we're logged in
    if (link == '/' && this.loggedIn) {
      link = '/dashboard';
    }

    this.storeService.navigate(link, this.router);
  }

  exposeNavigation(event: any) {
    event.preventDefault();
    this.navOn = !this.navOn;
  }

  hideNav(){
    this.navOn = false;
  }

  triggerLogout(event: any) {
    event.preventDefault();
    this.navOn = false;
    this.storeService.navigate('logout', this.router);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: any) {
    if (window.pageYOffset > 550) {
      let element = document.getElementById('loggedin--header-id');
      element?.classList.add('sticky');
    } else {
      let element = document.getElementById('loggedin--header-id');
      element?.classList.remove('sticky');
    }
  }
  ngOnDestroy() {
    this.obs.forEach(x => {
      x.unsubscribe();
    });
  }


  doTextChange(str: string) {
    if (str.length < 3) {
      return;
    }
    this.router.navigate(['/search'], {
      queryParams: {
        text: this.searchText,
        type: ''
      }
    });
  }


  accountLinkIsAvailable(str: string) {
    if (!this.suppressPrices) {
      return true;
    }
    return (str.indexOf('pricelist') > -1 || str.indexOf('statement') > -1) == false;
  }


  doSearch(event: any) {
    event.preventDefault();
    this.router.navigate(['/search'], {
      queryParams: {
        text: '',
        type: ''
      }
    });
  }

}
