export const termsText = `
<p class="font-bold">
THE FOLLOWING ARE EXTRACTS FROM THE STANDARD TERMS AND CONDITIONS OF
SALE OF LIKEWISE PLC (THE “SELLER”), THE FULL COPY OF THEM BEING
AVAILABLE ON REQUEST.
</p>
<p>1. ORDERS AND SPECIFICATIONS</p>
<p>
1.1 The Buyer shall be responsible to the Seller for ensuring the
accuracy of the terms of any order submitted by the Buyer.
</p>
<p class="pb-3">
1.2 no order which has been accepted by the Seller may be cancelled by
the Buyer except with the agreement in Writing of the Seller.
</p>

<p class="font-bold">2. PRICE OF THE GOODS</p>
<p>
2.1 The price of the Goods shall be the Seller’s quoted price or where
no price has been quoted or the price listed in the Seller’s published
price list current at the date of acceptance of the order.
</p>
<p class="pb-3">
2.2 The price is exclusive of any applicable value added tax.
</p>

<p class="font-bold">3. TERMS OF PAYMENT</p>
<p class="pb-3">
3.1 the Seller shall be entitled to invoice the Buyer for the price of
the Goods on or at any time after delivery of the Goods.
<br>
3.2 The Buyer shall pay the price of the Goods by the 20th of the
month following date of the Seller’s invoice.
<br>
3.3 If the Buyer fails to make any payment on the due date then the
Seller shall be entitled to:
<br>
3.3.1 cancel the contract or suspend any further deliveries to the
Buyer:
<br>
3.3.2 appropriate any payment made by the Buyer
<br>
3.3.3 charge the Buyer interest on the amount unpaid at the rate of
three per cent per annum above Barclays Bank base rate.
</p>

<p class="font-bold">4. RISK AND PROPERTY</p>
<p class="pb-3">
4.1 Risk of damage to or loss of the Goods shall pass to the Buyer:
<br>
4.1.1 in the case of Goods to be delivered at the Seller’s premises at
the
<br>
time when the Seller notifies the Buyer that the Goods are available
for collection: or
<br>
4.1.2 in the case of Goods to be delivered otherwise than at the
Seller’s premises at the time of delivery or if the Buyer wrongfully
fails to take delivery of the Goods the time when the Seller has
tendered delivery of the Goods.
<br>
4.2 Notwithstanding delivery and the passing of risk in the Goods or
any other provision of these Conditions the property in the Goods
shall not pass to the Buyer until the Seller has received payment in
full of the price of the Goods and all other goods agreed to be sold
by the Seller to the Buyer for which payment is then due.
<br>
4.3 Until such time as the property in the Goods passes to the Buyer
the Buyer shall hold the Goods as the Seller’s fiduciary agent and
bailee and shall keep the Goods separate from those of the Buyer and
third parties and properly stored protected and insured and identified
as the Seller’s property.
<br>
4.4 Until such time as the property in the Goods passes to the Buyer
the Seller shall be entitled at any time to require the Buyer to
deliver up the Goods to the Seller and if the Buyer fails to do so
forthwith (with the deemed consent of the Buyer) to enter upon any
premises of the Buyer or any third party where the Goods are stored
and repossess the Goods.
<br>
4.5 The Buyer shall not be entitled to pledge or in any way charge by
way of security for any indebtedness any of the Goods which remain the
property of the Seller but if the Buyer does so all moneys owing by
the Buyer to the Seller shall (without prejudice to any other right or
remedy of the Seller) forthwith become due and payable.
</p>

<p class="font-bold">5. EXCLUSION OF THE SELLER’S LIABILITY</p>
<p class="pb-3">
5.1 The Seller shall be under no liability in respect of any fault
arising from fair wear and tear willful damage negligence abnormal
working conditions failure to follow the Seller’s instructions
(whether oral or in writing) misuse or alteration or repair of the
Goods without the Seller’s approval.
<br>
5.2 The Seller shall be under no liability in respect of faults
occurring to parts materials or equipment not manufactured by the
Seller in respect of which the Buyer shall only be entitled to the
benefit of any such warranty or guarantee as is given by the
manufacturer to the Seller.
<br>
5.3 Any claim by the Buyer which is based on any fault in the quality
or condition of the Goods or their failure to correspond with
specification shall be notified to the Seller within 7 days from the
date of delivery.
</p>

<p class="font-bold">6. INSOLVENCY OF BUYER</p>
<p class="pb-3">
6.1 This clause applies if:
<br>
6.1.1 the Buyer makes any voluntary arrangement with its creditors or
becomes subject to an administration order or becomes bankrupt or goes
into liquidation, or
<br>
6.1.2 an encumbrancer take possession or a receiver is appointed of
any of the property or assets of the Buyer; or
<br>
6.1.3 the Buyer ceases or threatens to cease to carry on business; or
<br>
6.1.4 the Seller reasonably apprehends that any of the events
mentioned above is about to occur in relation to the Buyer and
notifies the Buyer accordingly.
<br>
6.2 If this clause applies then without prejudice to any other right
or remedy available to the Seller shall be entitled to cancel the
Contract or suspend any further deliveries under the Contract without
any liability to the Buyer and if the Goods have been delivered but
not paid for the price shall become immediately due and payable
notwithstanding any previous agreement or arrangement to the contrary.
</p>`;