<div class="footer" *ngIf="show">
    <div class="container">
        <div class="footer__top">
            <div class="row footer__top__first">
                <div class="col-lg-4 col-md-4">
                    <div class="footer-title colour-primary">
                        <span>
                            {{ config.companyName }}
                        </span>
                    </div>

                    <div class="company-details">
                        <ng-container *ngFor="let x of companyKeys">
                            <div *ngIf="companyDetails.hasOwnProperty( x )">
                                {{companyDetails[x]}}
                            </div>
                        </ng-container>
                    </div>


                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="footer-title colour-primary">
                        <span>
                            Navigation
                        </span>
                    </div>
                    <ul>
                        <li *ngFor="let o of footerNav" >
                            <a (click)="navigate( $event, o.link)">{{o.text}}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="footer-title colour-primary">
                        <span>
                            Additional Links
                        </span>
                    </div>
                    <ul>
                        <li *ngFor="let o of config.footerAdditional">
                            <a (click)="navigate( $event, o.link)">{{o.text}}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="footer-title colour-primary">
                        <span>Help and Support</span>
                    </div>
                    <p>
                        For more information or assistance, please contact our team.<br />
                        Sales: <a href="tel:{{footerContact.hasOwnProperty('support') && footerContact.support != '' ? footerContact.support : ''}}">{{footerContact.hasOwnProperty('support') && footerContact.support != '' ? footerContact.support : ''}}</a>
                        <br/>
                        Contract Sales Number: 
                        <a href="tel:{{footerContact.hasOwnProperty('sales') && footerContact.sales != '' ? footerContact.sales : ''}}">{{footerContact.hasOwnProperty('sales') && footerContact.sales != '' ? footerContact.sales : ''}}</a>
                        
                        <br/> 
                        Email: <a
                            href="mailto:{{ footerContact.email }}">{{   footerContact.email }}</a>
                    </p>


                    <div *ngIf="checkIfPathContainsProduct()" class="sticky-summary-button" (click)="scrollToSummary()">View Summary</div>
                </div>
            </div>

            <div class="footer-bottom mt-3">
                <div class="footer-bottom__copyright">
                    <p>© {{ year }} {{ config.copyrightText }} Designed and Powered by <a
                            href='https://www.sketchcreative.co/' target="_blank" rel="noreferrer">Sketch Creative</a>.
                    </p>
                </div>
                <div class="footer-bottom__scroll">
                    <p class="d-flex align-items-center justify-content-end"><a (click)="scrollToTop( $event )"><span
                                class="mr-2">Back to top</span><img src="/assets/images/icons/up-chevron.svg"
                                alt="Back to top" /></a>
                    </p>
                </div>
            </div>
        </div>

    </div>
</div>