import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { EnvironmentService, StoreService, Customer } from 'portal';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sketch-footer',
  templateUrl: './footer-component.component.html',
  styleUrls: ['./footer-component.component.scss']
})
export class FooterComponent {

  obs: Subscription[] = [];
  year: number = 0;
  config: any;
  customer?: Customer;
  @Input() loggedIn = false;
  show: boolean = false;
  footerContact: any;
  companyDetails: any;
  companyKeys: string[] = ['addressLineOne', 'addressLineTwo', 'town', 'postcode'];
  suppressPrices: boolean = false; 
  footerNav: any[] =  []; 
  filteredFooter: any[] = [];
  path = this.location.path();

  constructor(private storeService: StoreService, private envService: EnvironmentService, private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any,
    private router: Router, private location: Location,) { }
    
  ngOnInit(): void {
    this.year = new Date().getFullYear();

    this.obs.push(this.envService.config.subscribe(x => {
      this.config = x;
      this.footerNav = this.envService.extractSetting( x , 'footerNav', []);

      if (x.hasOwnProperty('footerContact')) {
        this.footerContact = x.footerContact;
      }
      if (x.hasOwnProperty('companyDetails')) {
        this.companyDetails = x.companyDetails;
      }    
    }));

    this.filteredFooter = this.footerNav.filter( y => this.accountLinkIsAvailable(y.link) );
    
    this.obs.push(this.storeService.getSuppressPrices().subscribe(x => {
      this.suppressPrices = x;
      if( this.suppressPrices ){
        this.filteredFooter = this.footerNav.filter( y => this.accountLinkIsAvailable(y.link) );
      }
    }));

    this.obs.push(this.storeService.customer.subscribe((x:any) => {
      this.customer = x;      
    }));

    this.obs.push(this.storeService.toggleCustomerId.subscribe((x:any) => {
      this.show = x != null && x != '';
    }));

  }

  navigate(event: any, link: string) {
    event.preventDefault();

    //Redirect to the dashboard if we click home and we're logged in
    if (link == '/' && this.loggedIn) {
      link = '/dashboard';
    }

    if (link == '/myaccount' && this.loggedIn) {
      link = '/account';
    }

    if (link == '/myorders' && this.loggedIn) {
      link = '/account/orders';
    }

    this.storeService.navigate(link, this.router);
  }

  scrollToTop(event: any) {
    event.preventDefault();


    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: 'body',
    });
  }

  scrollToSummary() {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '#summary',
    });
  }

  checkIfPathContainsProduct(): boolean {
    if (this.router.url.includes('/product')) {
      return true;
    } else {
      return false;
    }
    
  }

  accountLinkIsAvailable( str: string){
    if( !this.suppressPrices ){
      return true; 
    }
    return ( str.indexOf('pricelist')  > -1 || str.indexOf('statement') >-1 ) == false;
  }

  onNgDestroy() {
    this.obs.forEach(x => {
      x.unsubscribe();
    });
  }
}  