import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { TestComponentComponent } from './test-component/test-component.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FooterComponent } from './components/footer-component/footer-component.component';
import { HeaderComponent } from './components/header/header.component';
import { ComponentsModule } from 'portal';
import { HeaderSearchComponent , JwtInterceptor, ErrorInterceptor} from 'portal';
import { FormsModule } from '@angular/forms';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { environment } from '@environments/environment';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.webUrl // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#142C48'
    },
    button: {
      background: '#000000',
    }
  },
  content:{
    href: '/cookies',
  },
  theme: 'edgeless',
  type: 'opt-out'
};


@NgModule({
  declarations: [
    AppComponent,
    TestComponentComponent,
    FooterComponent,
    HeaderComponent,    
  ],
  imports: [
    BrowserModule,
    RouterModule,   
    AppRoutingModule, 
    HttpClientModule,
    FontAwesomeModule, 
    NgxPageScrollModule,
    SlickCarouselModule,
    FormsModule,
    ComponentsModule,
    ToastrModule.forRoot(),     
    NgcCookieConsentModule.forRoot(cookieConfig),
    GoogleTagManagerModule.forRoot({
      id:  environment.googleTagManager ,
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
