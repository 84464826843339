import { Component, Inject } from '@angular/core';
import { environment } from '@environments/environment';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Title } from "@angular/platform-browser";
import { EnvironmentService, StoreService, PopupService, TemplateService, setCustomerID, getCustomerID, Customer } from 'portal';
import { FooterComponent } from './components/footer-component/footer-component.component';
import { HeaderComponent } from './components/header/header.component';
import { HeroComponentComponent } from './components/hero-component/hero-component.component';
import { TestComponentComponent } from './test-component/test-component.component';
import { Subscription } from 'rxjs';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { GoogleTagManagerService } from 'angular-google-tag-manager';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentUrl: string = '';

  dontScrollPages: string[] = [
    'product', 'category', 'search'
  ];

  prevPage: any = null;
  prevNonSearchPage: any = null;
  config: any = null;
  obs: Subscription[] = [];
  cssClass: string = '';

  constructor(private envService: EnvironmentService, router: Router,
    private popupService: PopupService,
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any,
    private gtmService: GoogleTagManagerService,
    private titleService: Title,
    private storeService: StoreService, private templateService: TemplateService, private ccService: NgcCookieConsentService) {


    //Load custom templates
    this.templateService.addTemplate('sketch-footer', { input: null, ref: FooterComponent });
    this.templateService.addTemplate('sketch-header', { input: null, ref: HeaderComponent });

    this.initCookies();



    //TODO -- override input on hero component
    let x = getCustomerID();
    this.storeService.toggleCustomerId.next(x);

    envService.config?.next(environment);

    let showNav = router.url.indexOf('login') !== -1;
    this.popupService.toggleShowNav.next(showNav);


    this.envService.config.subscribe((x: any) => {
      this.storeService.setCompanyName(x.companyName);
      this.storeService.setTitle('');
      this.config = x;
    });



    router.events.forEach((event: any) => {


      if (event instanceof NavigationStart) {

        if (this.config.enableHomePage == false) {
          if (event.url == '' || event.url == '/') {
            router.navigate(['/login']);
            return;
          }
        }

        if (event.url.indexOf('logout') !== -1) {
          setCustomerID('');
          this.storeService.customerId = '';
          this.storeService.customerSubject?.next({} as Customer);
          router.navigate(['/login']);
          return
        }


        if (event.navigationTrigger === 'popstate') {
          if (null != this.prevPage && event.url.indexOf('search') !== -1) {
            router.navigate([this.prevPage]);
            return
          }
        }

        //Navigating
        if (event.url.indexOf('search') === -1) {
          this.prevPage = event.url;
        }

        //Hide nav on login page
        if (event.url.indexOf('login') !== -1) {
          this.storeService.toggleCustomerId.next('');
          this.popupService.toggleShowNav.next(false);
        } else {
          this.popupService.toggleShowNav.next(true);
        }
      }




      if (event instanceof NavigationEnd) {

        if (environment.googleTagManager != '') {
          const gtmTag = {
            event: 'page',
            pageName: event.url
          };

          this.gtmService.pushTag(gtmTag);
        }
        let cssClass = event.url.substring(1).split('/').join('-');

        //Remove old one
        if (this.cssClass != '') {
          this.document.body.classList.remove(this.cssClass);
        }
        //Add new one
        this.document.body.classList.add(cssClass);
        this.cssClass = cssClass;

        //Don't scroll if we move between paging instances on these pages
        for (let i = 0; i < this.dontScrollPages.length; i++) {
          if (this.currentUrl.indexOf(this.dontScrollPages[i]) > 0 && event.url.indexOf(this.dontScrollPages[i]) > 0) {
            this.currentUrl = event.url;
            return;
          }
        }

        this.currentUrl = event.url;

        //Scroll to top
        this.pageScrollService.scroll({
          document: this.document,
          scrollTarget: 'body',
        });
      }
    });
  }

  initCookies() {


    if (this.storeService.getAllowCookies()) {
      this.ccService.destroy();
    }
    // subscribe to cookieconsent observables to react to main events
    this.obs.push(this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }));

    this.obs.push(this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }));

    this.obs.push(this.ccService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        // console.log(`initializing: ${JSON.stringify(event)}`);
      }));

    this.obs.push(this.ccService.initialized$.subscribe(
      () => {
        if (this.storeService.getAllowCookies()) {
          this.ccService.close(false);
        }
      }));

    this.obs.push(this.ccService.initializationError$.subscribe(
      (event: NgcInitializationErrorEvent) => {
        // the cookieconsent has failed to initialize...
        // console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
      }));

    this.obs.push(this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status == 'allow') {
          this.storeService.setAllowCookies();
        }
      }));

    this.obs.push(this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }));

    this.obs.push(this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }));
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.obs.forEach(obs => obs.unsubscribe());
  }
}

